import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import TicketPreview from 'components/TicketPreview/TicketPreview';
import CristalLoader from 'components/CristalLoader';

const StageStyled = styled.div`
	
`;

const Form = styled.div`
	.form-check-input:checked {
		background-color: var(--plenoil-blue2);
    	border-color: var(--plenoil-blue2);
    	box-shadow: none;
	}

	#cliente-registrado {
		label {
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
		}
	}
`;

const TicketWrapper = styled.table`
	margin-bottom: 20px;
	position: relative;
	
	td {
		&:not(:nth-child(1)) {
			vertical-align: top; 
			padding-right: 10px;
		}
	}

	button {
		border: 0;
		background: var(--bs-danger);
		color: white;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		font-size: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;


		&:hover {
			background: white;
			color: var(--bs-danger);
			border: 1px solid var(--bs-danger);
		}
	}

	.label-plenoil {
		text-transform: uppercase;
	}
`;

const TicketPreviewWrapper = styled.div`
	padding-left: 10px;

	h6 {
		margin-top: 20px;
		text-align: center;
	}

	@media (max-width: 768px) {
		margin-top: 40px;
	}
`;

const TicketDisplacer = styled.div`
	@media (min-width: 768px) {
		height: ${props => props.height}px;
		transition: all 0.5s ease-out;

	}
`;

const ButtonNext = styled.button`
	width: 100%;
	max-width: 120px;
	margin-left: 20px;
`;

const ticketTemplate = {
	number: null,
	amount: null,
	date: null
};

let axiosCancelToken = null;

const First = (props) => {
	let data = props.data;
	let setData = props.setData;
	let goNextStage = props.goNextStage;

	let lastTicketRef = useRef(null);
	let ticketDisplacerRef = useRef(null);
	let ticketPreviewRef = useRef(null);

	let [loading, setLoading] = useState(false);
	let [displacerHeight, setDisplacerHeight] = useState(0);
	let [errors, setErrors] = useState({});
	let [fieldOutlined, setFieldOutlined] = useState(null);
	let [lastTicketIdx, setLastTicketIdx] = useState(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const setDataField = useCallback((field, value) => {
		setData((data) => ({...data, [field]: value}));
	}, [setData]);

	const addTicket = useCallback((ticketData) => {
		let newTickets = [...data.tickets];
		newTickets.push(ticketData);
		setDataField('tickets', newTickets);

		setLastTicketIdx(newTickets.length-1);
	}, [data.tickets, setDataField]);

	const removeTicket = (idx) => {
		let newTickets = [...data.tickets];
		newTickets.splice(idx, 1);
		setDataField('tickets', newTickets);
	}

	const setTicketField = (idx, field, value) => {
		let newTickets = [...data.tickets];
		newTickets[idx][field] = value;
		setDataField('tickets', newTickets);
	}

	const ticketFocusCallback = (e) => {
		let ticketTop = e.currentTarget.offsetTop;
		let displacerHeight = ticketTop - ticketDisplacerRef.current.offsetTop - (ticketPreviewRef.current.clientHeight/4);
		if ( displacerHeight < 0 ) displacerHeight = 0;
		setDisplacerHeight(displacerHeight);
	}

	const checkIfTicketsAreFree = (tickets) => {
		return axios.post('api/tickets-to-invoices/check-free-tickets', {
			tickets: tickets
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			return response.data.tickets;
		});
	}

	const checkData = async () => {
		let errors = {};
		setErrors([]);
		setLoading(true);

		data.tickets.forEach((el, idx) => {
			let errTmp = {};
			if ( !el.number || el.number.trim().length <= 0 ) errTmp.number = 'Introduce el albaran';
			if ( !el.amount || isNaN(el.amount) || parseFloat(el.amount) <= 0 ) errTmp.amount = 'Introduce el importe';
			if ( !el.date ) errTmp.date = 'Introduce la fecha';

			let duplicated = data.tickets.filter((fEl, fIdx) => fIdx !== idx && fEl.number === el.number).number ? true : false;
			if ( duplicated ) errTmp.number = 'Ya has introducido este ticket, por favor introduce otro';

			if ( Object.keys(errTmp).length > 0 ) errors[idx] = errTmp;
		});

		// Ajax call to check every ticket and set errors or continue
		let ticketsCheckResult = await checkIfTicketsAreFree(data.tickets);
		for(let i in ticketsCheckResult) {
			if ( ticketsCheckResult[i] ) continue;
			if ( !errors[i] ) errors[i] = {};
			errors[i].number = 'Ticket no válido';
		}

		if ( Object.keys(errors).length === 0 ) goNextStage();

		setLoading(false);
		setErrors(errors);
	}

	useEffect(() => {
		if ( data.tickets.length === 0 ) addTicket({...ticketTemplate});
	}, [data.tickets, addTicket]);

	useEffect(() => {
		if ( lastTicketIdx ) {
			lastTicketRef.current.focus();
			setLastTicketIdx(null);
		}
	}, [lastTicketIdx]);

	return (
		<StageStyled className="row">
			{ loading && <CristalLoader /> }
			<Form className="col-md-8">
				<div className="box-plenoil box-plenoil-padded">
					<div className="mb-2" id="cliente-registrado">
						<label className="me-3">¿Eres cliente registrado?</label>
						<div className="form-check d-inline-block me-3">
							<input className="form-check-input" type="radio" id="si" checked={data.isClient} onChange={() => setDataField('isClient', true)} />
							<label className="form-check-label" htmlFor="si">Si</label>
						</div>
						<div className="form-check d-inline-block">
							<input className="form-check-input" type="radio" id="no" checked={!data.isClient} onChange={() => setDataField('isClient', false)} />
							<label className="form-check-label" htmlFor="no">No</label>
						</div>

						<h6 className="mt-4 mb-4">Introduzca los datos de su ticket</h6>
					</div>

					{data.tickets.map((el, idx) => {
						return (
							<TicketWrapper key={idx} onFocus={(e) => ticketFocusCallback(e)}>
								<tbody>
									<tr>
										<td>
											<label className="label-plenoil">&nbsp;</label>
											{ data.tickets.length > 1 && <button onClick={() => removeTicket(idx)}>&times;</button> }	
											<div className="invalid-feedback d-block">&nbsp;</div>
										</td>
										<td>
											<label className="label-plenoil">Albarán</label>
											<input type="text" className="input-plenoil" ref={idx === lastTicketIdx ? lastTicketRef : null} onChange={(e) => setTicketField(idx, 'number', e.target.value)} value={el.number ?? ''} onFocus={() => setFieldOutlined('number')} onBlur={() => setFieldOutlined(null)} />
											{ (errors[idx] && errors[idx].number) && 
												<div className="invalid-feedback d-block">{errors[idx].number}</div>
											}
										</td>
										<td>
											<label className="label-plenoil">Importe</label>
											<input type="number" className="input-plenoil no-arrows" onChange={(e) => setTicketField(idx, 'amount', e.target.value)} value={el.amount ?? ''} onFocus={() => setFieldOutlined('total')} onBlur={() => setFieldOutlined(null)} />
											{ (errors[idx] && errors[idx].amount) && 
												<div className="invalid-feedback d-block">{errors[idx].amount}</div>
											}
										</td>
										<td>
											<label className="label-plenoil">Fecha</label>
											<input type="date" className="input-plenoil" onChange={(e) => setTicketField(idx, 'date', e.target.value)} value={el.date ?? ''} onFocus={() => setFieldOutlined('date')} onBlur={() => setFieldOutlined(null)} />
											{ (errors[idx] && errors[idx].date) && 
												<div className="invalid-feedback d-block">{errors[idx].date}</div>
											}
										</td>
									</tr>
								</tbody>
							</TicketWrapper>
						);
					})}
				</div>
				{ data.tickets.length > 0 &&
					<div className="mt-4 small text-plenoil-primary text-center"><b>Importante:</b> si tienes tickets de diferentes estaciones, tendrás que canjearlos por separado. No se emiten facturas con tickets de diferentes estaciones.</div>
				}
				<div className="text-center mt-4">
					{ Object.keys(errors).length > 0 &&
						<div className="invalid-feedback d-block mb-3">
							<h5>Hay errores que debes corregir antes de continuar</h5>
						</div>
					}
				</div>
				<div className="text-center d-flex justify-content-center mt-4">
					<button className="btn-plenoil btn-sm btn-plenoil-blue2 outline" onClick={() => addTicket({...ticketTemplate})}>Añadir otro ticket <i className="bi bi-plus"></i></button>
					<ButtonNext className="btn-plenoil btn-plenoil-blue2" onClick={() => checkData()}>Siguiente</ButtonNext>
				</div>
			</Form>
			<TicketPreviewWrapper className="col-md-4">
				<TicketDisplacer ref={ticketDisplacerRef} height={displacerHeight} />
				<div ref={ticketPreviewRef}>
					<TicketPreview fieldOutlined={fieldOutlined} />
				</div>
				<h6 className="text-plenoil-primary small">Ticket de ejemplo con la ubicación de los datos</h6>
			</TicketPreviewWrapper>
		</StageStyled>
	);
}

export default First;

export const breadcrumb = 'Solicitar factura';
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import First, { breadcrumb as breadcrumbFirst } from 'stages/First';
import Second, { breadcrumb as breadcrumbSecond } from 'stages/Second';
import Third, { breadcrumb as breadcrumbThird } from 'stages/Third';

import logo from 'img/logo.svg';

const Container = styled.div`
	max-width: 1000px;
	margin: 0 auto;
	margin-top: 50px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 20px;
`;

const Header = styled.div`
	display: flex;
	justify-content: center;
	box-shadow: var(--plenoil-shadow);
	padding: 20px;

	#logo {
		width: 200px;
	}
`;

const BreadCrumbs = styled.div`
	display: flex;
	text-transform: uppercase;
	margin-bottom: 30px;
	white-space: nowrap;
`;

const BreadCrumbPart = styled.div`
	font-weight: ${props => props.active ? 'bold' : 'normal'};
	color: ${props => props.active ? '' : 'var(--bs-gray-600)'};
`;

const stagesArr = {
	1: First,
	2: Second,
	3: Third
};

function App() {
	let debug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

    axios.defaults.baseURL = debug ? 'http://plenoilpanel.local/' : 'https://panel.plenoilapp.com/';

	const [stage, setStage] = useState(1);
	const [data, setData] = useState({
		isClient: false,
		newClientData: {},
		tickets: []
	});

	const StageComponent = stagesArr[stage];

	return (
		<>
			<Header><img src={logo} id="logo" alt="logo" /></Header>
			<Container>
				{ stage < 3 &&
					<React.Fragment>
						<BreadCrumbs>
							<BreadCrumbPart active={stage === 1}>{breadcrumbFirst} >&nbsp;</BreadCrumbPart>
							<BreadCrumbPart active={stage === 2}>{breadcrumbSecond} >&nbsp;</BreadCrumbPart>
							<BreadCrumbPart active={stage === 3}>{breadcrumbThird}</BreadCrumbPart>
						</BreadCrumbs>
					</React.Fragment>
				}
				<StageComponent  
					data={data}
					setData={setData}
					goNextStage={() => setStage(stage+1)}
				/>
			</Container>
		</>
	);
}

export default App;

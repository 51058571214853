import React from 'react';
import styled from 'styled-components';

const StageStyled = styled.div`
	text-align: center;
	
	p {
		font-weight: 400;
		font-size: 15px;
	}
`;

const Third = () => {

	return (
		<StageStyled>
			<div className="box-plenoil box-plenoil-padded">
				<p className="text-center">
					Tu factura ya ha sido enviada al correo electrónico<br />
					indicado en tus datos. Recuerda que también puedes<br />
					descargar tus facturas ya solicitadas en la página<br />
					<b>Facturas</b> de tu Área de Cliente.
				</p>

				<p className="text-center">
					<i className="bi bi-envelope-fill" style={{fontSize: '100px', color: 'var(--plenoil-blue2)', opacity: 0.15}}></i>
				</p>

				<p className="text-center">
					<a href="https://plenoil.es/" className="btn-plenoil btn-plenoil-blue2 outline text-decoration-none">Volver a Plenoil.es</a>

				</p>
			</div>
		</StageStyled>
	);
}

export default Third;

export const breadcrumb = 'Confirmación';
import React, { useEffect } from 'react'; 
import styled from 'styled-components';

import LoaderImg from 'img/loader.png';

const LoaderStyled = styled.div`
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	z-index: 999999999;
    background: rgba(0,0,0,0.2);

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100px;
        height: 100px;
        -webkit-animation:spin 1s ease infinite;
        -moz-animation:spin 1s ease infinite;
        animation:spin 1s ease infinite;
    }

    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform:  rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform:  rotate(360deg); transform: rotate(360deg); } }
`;

const Loader = () => {

    useEffect(() => {
        document.activeElement.blur();

        const preventKeyEvent = (e) => {
            e.preventDefault();
        }
        document.addEventListener('keydown', preventKeyEvent);

        return function cleanup() {
            document.removeEventListener('keydown', preventKeyEvent);
        }
    }, []);

	return (
		<LoaderStyled>
			<img src={LoaderImg} alt="loader" />
		</LoaderStyled>
	);
}

export default Loader;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import CristalLoader from 'components/CristalLoader';

const StageStyled = styled.div`
	
	.label-plenoil {
		text-transform: uppercase;
	}
`;

const ButtonNext = styled.button`
	display: block;
	width: 150px;
	margin: 0 auto;
	margin-top: 20px;
`;

let axiosCancelToken = null;

const Second = (props) => {
	let data = props.data;
	let setData = props.setData;
	let goNextStage = props.goNextStage;
	let newClientData = data.newClientData;

	let [loading, setLoading] = useState(false);
	let [errors, setErrors] = useState({});
	let [existingUserVatnumber, setExistingUserVatnumber] = useState(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const setDataField = (field, value) => {
		let newData = {...newClientData};
		newData[field] = value;
		setData((data) => ({...data, newClientData: newData}));
	}

	const checkDataAndCreate = async () => {
		setErrors({});
		setLoading(true);

		// Check fields
		let errors = {};
		if ( !existingUserVatnumber ) {
			if ( !newClientData.vatnumber || newClientData.vatnumber.trim().length <= 0 ) errors.vatnumber = 'Campo obligatorio';
		} else {
			if ( existingUserVatnumber.trim().length <= 0 ) errors.vatnumber = 'Campo obligatorio';
		}

		// Check if client exists
		if ( Object.keys(errors).length === 0 ) {
			let clientExists = await axios.get('api/tickets-to-invoices/check-client-exists', {
				params: {
					vatnumber: data.isClient ? existingUserVatnumber : newClientData.vatnumber
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				return response.data.status === 1;
			}).catch((error) => {
				errors.vatnumber = 'Ha ocurrido un error al comprobar el Cif / Nif';
			});
			if ( existingUserVatnumber && !clientExists ) errors.vatnumber = 'No existe ningún cliente registrado con ese Nif / Cif';
			if ( !existingUserVatnumber && clientExists ) errors.vatnumber = 'Ya existe un cliente registrado con este Cif / Nif, por favor, introduce otro';
		}
		// Generate invoice (and create user if needed)
		if ( Object.keys(errors).length === 0 ) {
			// Generate invoice
			await axios.post('api/tickets-to-invoices/generate-invoice', {
				tickets: data.tickets,
				client: newClientData.vatnumber ? {...newClientData} : {vatnumber: existingUserVatnumber},
				client_exists: existingUserVatnumber ? 1 : 0
			}, {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				goNextStage();
			}).catch((error) => {
				console.log(error);
				if ( error.response.data.errors ) errors = error.response.data.errors ?? {};
				errors.general = 'Ha ocurrido un error al generar la factura. Por favor inténtelo de nuevo.';
			});
		}
		
		setLoading(false);
		setErrors(errors);
	}

	return (
		<StageStyled className="row">
			{ loading && <CristalLoader /> }

			<div className="box-plenoil box-plenoil-padded">
				{ data.isClient &&
					<React.Fragment>
						<div className="col-md-12">
							<div className="d-flex justify-content-center">
								<div className="d-flex flex-column">
									<label className="mb-4 text-center">Identifícate y enviaremos tu factura al correo<br />electrónico indicado en tus datos</label>
									<input className="input-plenoil" type="text" onChange={(e) => setExistingUserVatnumber(e.target.value)} value={existingUserVatnumber ?? ''} placeholder="NIF/CIF" />
									{ errors.vatnumber && 
										<div className="invalid-feedback d-flex justify-content-center">{errors.vatnumber}</div>
									}
								</div>
							</div>
						</div>
					</React.Fragment>
				}

				{ !data.isClient &&
					<React.Fragment>
						<div className="col-md-12">
							<div className="row">
								<label className="mb-4 text-center">Identifícate y enviaremos tu factura al correo<br />electrónico indicado en tus datos</label>

								<div className="col-md-3 mb-2">
									<label className="label-plenoil">Nombre</label>
									<input className="input-plenoil" type="text" onChange={(e) => setDataField('name', e.target.value)} value={newClientData.name ?? ''} />
									{ errors['client.name'] && 
										<div className="invalid-feedback d-block">{errors['client.name']}</div>
									}
								</div>
								<div className="col-md-3 mb-2">
									<label className="label-plenoil">Ciudad</label>
									<input className="input-plenoil" type="text" onChange={(e) => setDataField('city', e.target.value)} value={newClientData.city ?? ''} />
									{ errors['client.city'] && 
										<div className="invalid-feedback d-block">{errors['client.city']}</div>
									}
								</div>
								<div className="col-md-3 mb-2">
									<label className="label-plenoil">Código postal</label>
									<input className="input-plenoil" type="text" onChange={(e) => setDataField('postalcode', e.target.value)} value={newClientData.postalcode ?? ''} />
									{ errors['client.postalcode'] && 
										<div className="invalid-feedback d-block">{errors['client.postalcode']}</div>
									}
								</div>
								
								<div className="col-md-3 mb-2">
									<label className="label-plenoil">Cif / Nif</label>
									<input className="input-plenoil" type="text" onChange={(e) => setDataField('vatnumber', e.target.value)} value={newClientData.vatnumber ?? ''} />
									{ errors['client.vatnumber'] && 
										<div className="invalid-feedback d-block">{errors['client.vatnumber']}</div>
									}
									{ errors.vatnumber && 
										<div className="invalid-feedback d-block">{errors.vatnumber}</div>
									}
								</div>
								<div className="col-md-9 mb-2">
									<label className="label-plenoil">Dirección</label>
									<input className="input-plenoil" type="text" onChange={(e) => setDataField('address', e.target.value)} value={newClientData.address ?? ''} />
									{ errors['client.address'] && 
										<div className="invalid-feedback d-block">{errors['client.address']}</div>
									}
								</div>
								<div className="col-md-3 mb-2">
									<label className="label-plenoil">Email</label>
									<input className="input-plenoil" type="text" onChange={(e) => setDataField('email', e.target.value)} value={newClientData.email ?? ''} />
									{ errors['client.email'] && 
										<div className="invalid-feedback d-block">{errors['client.email']}</div>
									}
								</div>
							</div>
						</div>
					</React.Fragment>
				}

				<ButtonNext className="btn-plenoil btn-plenoil-blue2" onClick={() => checkDataAndCreate()}>Solicitar factura</ButtonNext>
			</div>

			<div className="col-md-12">
				<div className="text-center mt-4">
					{ errors.general && 
						<div className="invalid-feedback d-block mb-3">{errors.general}</div>
					}

					{ Object.keys(errors).length > 0 &&
						<div className="invalid-feedback d-block mb-3">
							<h5>Hay errores que debes corregir antes de continuar</h5>
						</div>
					}
				</div>
			</div>
		</StageStyled>
	);
}

export default Second;

export const breadcrumb = 'Datos personales';
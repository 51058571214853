import React from 'react';

import './TicketPreview.css';

class TicketPreview extends React.Component {

	state = {
		data: null
	}

	render() {
		const self = this;

		// Create separator
		let separator = "";
		for(let i = 0; i<=100; i++) separator += "*";

		// Render
		return (
			<div className="ticketPreview">
				<div className="ticket">
					<div className="ticketBody">
						<div className="section first">
							<div className="left">
								<div className="stationName">
									MADRID II
								</div>
								<div className="companyData">
									<div>Plenoil, S.L.</div>
									<div>cif: B93275394</div>
									<div>fecha: <span className={'outline ' + (self.props.fieldOutlined === 'date' ? 'outlined' : '')}>01/05/2022 21:34</span></div>
									<div>turno: 1475</div>
								</div>
							</div>
							<div className="right">
								<div>ALBARÁN:</div>
								<div><span className={'outline ' + (self.props.fieldOutlined === 'number' ? 'outlined' : '')}>R123456789123</span></div>
							</div>
						</div>

						<div className="section second">
							<div className="header">
								<div className="left">
									<b>Datos</b>
								</div>
								<div className="right">
									<b>Venta por Aceptador</b>
								</div>
							</div>
							<div className="separator">
								{separator}
							</div>
							<div className="body">
								<div className="left">
									<div>SURTIDOR:</div>
									<div>PRODUCTO:</div>
									<div>LITROS:</div>
									<div>PRECIO:</div>
									<div>IMPORTE:</div>
								</div>
								<div className="right">
									<div>2</div>
									<div>Gasóleo A</div>
									<div>30.92</div>
									<div>1.817</div>
									<div>56.18</div>
								</div>
							</div>
						</div>

						<div className="separator">
							{separator}
						</div>

						<div className="section third">
							<div className="left">
								<div>IVA:</div>
								<div>B. IMP.:</div>
								<div>IMP. IVA:</div>
							</div>
							<div className="right">
								<div>21.00%</div>
								<div>46.43</div>
								<div>9.75</div>
							</div>
						</div>

						<div className="separator">
							{separator}
						</div>

						<div className="section fourth">
							<div className="left">
								<div>TOTAL DESCUENTO:</div>
								<div>TOTAL RESERVADO:</div>
								<div>TOTAL SUMINISTRADO:</div>
								<div>TOTAL DEVUELTO:</div>
							</div>
							<div className="right">
								<div>-0.00 EUR</div>
								<div>50.00 EUR</div>
								<div><span className={'outline ' + (self.props.fieldOutlined === 'total' ? 'outlined' : '')}>56.18 EUR</span></div>
								<div>0.00 EUR</div>
							</div>
						</div>

						<div className="section last">
							{/*<div className="realdecreto">
								DESCUENTO APLICADO DE 20 CÉNTIMOS SEGÚN REAL DECRETO LEY 6/2022 DE 29 DE MARZO DE 2022
							</div>*/}

							<div className="paymenttype">
								Forma de Pago: Tarjetas bancarias
							</div>
							<div className="taxes">
								Imp. Esp. incl. (Eu/1000lt): Estatales = 349 Autonómico = 0
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

}

export default TicketPreview;